import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import HelpLayout from '../../components/HelpLayout';
import Card from '../../components/Card';

const trackFAQ = (faq) => {
  window.gtag('event', 'show_faq', {
    faq,
  });
};

const FAQAccordion = ({ items }) => {
  return (
    <ul>
      {items.map(([question, answer]) => (
        <Disclosure
          as="li"
          key={question}
          className="mb-4 bg-white rounded-lg last:mb-0 lg:mb-6"
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  `p-4 flex items-center justify-between w-full text-left appearance-none`
                )}
                onClickCapture={() => !open && trackFAQ(question)}
              >
                {question}
                <ChevronDownIcon
                  className={classNames('w-7 h-7 flex-shrink-0', {
                    'transform rotate-180': open,
                  })}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-4 text-sm">
                {answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </ul>
  );
};

const FAQPage = ({ location, data }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="FAQ"
        description="Do you have some questions about our service? We bring you the answers to the most frequently asked ones."
      />
      <Card>
        <Card.Heading>FAQ</Card.Heading>
        <Card.Body>
          <FAQAccordion
            items={[
              [
                'Why do I need your service at all?',
                <>
                  <p>Please watch the explainer video below.</p>
                  <div>
                    <div
                      style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                    >
                      <iframe
                        src={data.site.siteMetadata.faqVideoUrl}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        title="wolvido-faq"
                      ></iframe>
                    </div>
                  </div>
                </>,
              ],
              [
                'How it works?',
                <>
                  <p>
                    You upload the videos you want to publish to our service.
                  </p>
                  <p>
                    Then you just need to{' '}
                    <Link to="/">place a tiny HTML snippet</Link> in the section
                    of your webpage where the video should appear. Or you can
                    share the video using the direct link (e.g. via email or
                    social networks).
                  </p>
                  <p>
                    And that's it! We handle all the heavy work such as video
                    optimization and distribution to your viewers for you.
                  </p>
                </>,
              ],
              [
                'What are the limits?',
                <p>
                  Besides the video capacity limit determined by your plan,
                  there is also a soft bandwidth limit of 500 GB per month,
                  which should be enough for 99% of the users.
                </p>,
              ],
              [
                'What video formats do you support?',
                <p>
                  We support the most common video formats - MP4, MKV, MOV, AVI,
                  FLV, MPEG-2 TS, MPEG-2 PS, MXF, LXF, GXF, 3GP, WebM, MPG,
                  QuickTime.
                </p>,
              ],
              [
                'How do I embed the video in my webpage?',
                <p>
                  Please check the instructions{' '}
                  <Link to="/help/" className="border-b">
                    here
                  </Link>
                  .
                </p>,
              ],
              [
                'Will third-party ads appear on my videos?',
                <p>No way. Every our plan is completely without ads.</p>,
              ],
              ['Can I cancel anytime?', <p>Yes, you can cancel anytime.</p>],
              [
                `Didn't find your question?`,
                <p>
                  <Link to="/help/contact/" className="border-b">
                    Contact us
                  </Link>
                  . We'll be happy to help.
                </p>,
              ],
            ]}
          />
        </Card.Body>
      </Card>
    </HelpLayout>
  );
};

export const query = graphql`
  query FAQPageQuery {
    site {
      siteMetadata {
        faqVideoUrl
      }
    }
  }
`;

export default FAQPage;
